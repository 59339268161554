import classes from './Footer.module.css';
import { Link } from "react-router-dom";
import mainclasses from '../style.module.css';
const Footer = (props) => {
    const year = new Date().getFullYear();
    return (
        <footer className={`${classes.footer} ${mainclasses.noprint}`}>
            <div className="container-fluid mt-5 noprint">
                <div className="row">
                    <div className="col-md-10 mx-auto p-4 text-center">
                        <div>
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/contact">Contact Us</Link></li>
                                <li><Link to="/security">Security</Link></li>
                                <li><Link to="/anti-spam-policy">Anti-spam Policy</Link></li>
                                <li><Link to="/terms">Terms of Service</Link></li>
                                <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                <li><Link to="/cookie-policy">Cookie Policy</Link></li>
                            </ul>
                        </div>
                        <div>
                            <p>© {year} Skill Lotto Solutions Pvt. Ltd.All Rights Reserved</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;