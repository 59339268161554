import { Helmet } from "react-helmet";
import pricingClasses from "./Pricing.module.css";
import mainclasses from "../style.module.css";

const Pricing = () => {
      const data = [{
          "id":"1",
          "plan_name":"Base",
          "plan_price":"Free",
          "plan_price_quator":"Free",
          "plan_price_annual":"Free",
          "plan_price_details":"Price/Company/Month",
          "plan_features":
              "Upto 10 GB Storage"
      },
      {
          "id":"2",
          "plan_name":"Silver",
          "plan_price":"₹2,500 ",
          "plan_price_quator":"₹7,500 ",
          "plan_price_annual":"₹30,000",
          "plan_price_details":"Price/Company/Month",
          "plan_features":
              "Upto 50 GB Storage",
          "popular":1
      },{
          "id":"3",
          "plan_name":"Silver Plus",
          "plan_price":"₹5,000",
          "plan_price_quator":"₹15,000 ",
          "plan_price_annual":"₹60,000",
          "plan_price_details":"Price/Company/Month",
          "plan_features":
              "Upto 100 GB Storage"
      },{
          "id":"4",
          "plan_name":"Gold",
          "plan_price":"₹25,000",
          "plan_price_quator":"₹75,000 ",
          "plan_price_annual":"₹3,00,000",
          "plan_price_details":"Price/Company/Month",
          "plan_features":
              "Upto 1000 GB Storage"
      },{
          "id":"5",
          "plan_name":"Platinum",
          "plan_price":"₹75,000",
          "plan_price_quator":"₹2,25,000",
          "plan_price_annual":"₹9,00,000",
          "plan_price_details":"Price/Company/Month",
          "plan_features":
              "Upto 2000 GB Storage"
      },{
          "id":"6",
          "plan_name":"Diamond",
          "plan_price":"₹1,50,000",
          "plan_price_quator":"₹4,50,000",
          "plan_price_annual":"₹18,00,000",
          "plan_price_details":"Price/Company/Month",
          "plan_features":
              "Upto 5000 GB Storage"
      },{
          "id":"7",
          "plan_name":"Ultimate",
          "plan_price":"₹2,50,000",
          "plan_price_quator":"₹7,50,000",
          "plan_price_annual":"₹30,00,000",
          "plan_price_details":"Price/Company/Month",
          "plan_features":
              "Upto 10000 GB Storage"
      }
      ];

  return (
    <>
      <Helmet>
        <title>Sapid Books-Pricing</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="description"
          content="Powerful Accounting Platform For Our Business"
        />
      </Helmet>
      <div className="container my-1">
        <h2 className={mainclasses.heading}>Plans to Get You Started</h2>
        <div className="table-responsive">
          <table className="table table-bordered text-center">
            <thead className="table-light">
              <tr>
                <th className={pricingClasses.tableheader}>All Plans</th>
                <th className={pricingClasses.tableheader}>Space</th>
                <th className={pricingClasses.tableheader}>Price(Monthly)</th>
                <th className={pricingClasses.tableheader}>Price(Quarterly)</th>
                <th className={pricingClasses.tableheader}>Price(Annually)</th>
              </tr>
            </thead>
            <tbody>
              {data.map((plan)=>{
                return (<tr key={plan.id}>
                  <td className={pricingClasses.pricingheader}>{plan.plan_name}</td>
                  <td className={pricingClasses.featureheader}>{plan.plan_features}</td>
                  <td className={pricingClasses.pricingprice}>{plan.plan_price}</td>
                  <td className={pricingClasses.pricingprice}>{plan.plan_price_quator}</td>
                  <td className={pricingClasses.pricingprice}>{plan.plan_price_annual}</td>
                </tr>)
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Pricing;
