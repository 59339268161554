import { Routes, Route } from "react-router-dom";
import Home from "./Home/Home";
import About from "./About/About";
import Contact from "./Contact/Contact";
import CookiePolicy from "./CookiePolicy/CookiePolicy";
import MainHeader from "./Header/MainHeader";
import Footer from "./Footer/Footer";
import Download from "./Download/Download";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
import Terms from "./Terms/Terms";
import Security from "./Security/Security";
import AntispamPolicy from "./AntispamPolicy/AntispamPolicy";
import Pricing from "./Pricing/Pricing";

function App() {
  return (
    <>
      <MainHeader />
      <Routes >
        <Route path="/" element={ <Home/> } />
        <Route path="contact" element={ <Contact/> } />
        <Route path="about" element={ <About/> } />
        <Route path="download" element={ <Download/> } />
        <Route path="privacy-policy" element={ <PrivacyPolicy/> } />
        <Route path="cookie-policy" element={<CookiePolicy />} />
        <Route path="terms" element={<Terms />} />
        <Route path="anti-spam-policy" element={<AntispamPolicy />} />
        <Route path="security" element={<Security />} />
        <Route path="pricing" element={<Pricing />} />
        {/* <Route path="about" element={ <About/> } />
        <Route path="contact" element={ <Contact/> } /> */}
      </Routes>
    <Footer />
    </>
  );
}

export default App;
